import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme.js';
import ScrollFromTop from './ScrollFromTop';

const Home = lazy(() => import('./pages/Home/Home.jsx'));
const About = lazy(() => import('./pages/About/About.jsx'));
const Services = lazy(() => import('./pages/Services/Services.jsx'));
const Gallery = lazy(() => import('./pages/Gallery/Gallery.jsx'));
const Themes = lazy(() => import('./pages/Themes/Themes.jsx'));
const Pricing = lazy(() => import('./pages/Pricing/Pricing.jsx'));
const Blog = lazy(() => import('./pages/Blog/Blog.jsx'));
const PrivacyandPolicy = lazy(() => import('./Components/PrivacyandPolicy/PrivacyandPolicy.jsx'));
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs.jsx'));
const TermsAndConditions = lazy(() => import('./Components/TandC/TandC.jsx'));
const NotFound = lazy(() => import('./NotFound.jsx')); // Import the 404 component


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider theme={theme}>
    <Router>
      <ScrollFromTop />
      <Suspense fallback={<div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        fontSize:'2.5em',
        fontWeight:'800',
        color:'#3A5B7A'
      }}>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/themes" element={<Themes />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/privacyandpolicy" element={<PrivacyandPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="*" element={<NotFound />} /> {/* Fallback route */}
        </Routes>
      </Suspense>
    </Router>
  </ChakraProvider>
);
